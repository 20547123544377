"use client";
import type { newsFilter } from "app/actions";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";

// no need to do calculation to get first post year, we already know it's 2016.
const start_year = 2016;
const current_year = new Date().getFullYear();

type inner_update = (old: newsFilter) => newsFilter;

export default function NewsFilter({
  filter,
  categories,
  tags,
  update_filter,
}: {
  filter: newsFilter;
  categories: Record<number, string>;
  tags: Record<number, string>;
  update_filter: (fn: inner_update) => void;
}) {
  const arr_to_year = new Array(current_year - start_year + 1)
    .fill(0)
    .map((_, i) => start_year + i);
  const [years, setYears] = useState({} as Record<number, string>);
  useEffect(() => {
    for (const i of arr_to_year) {
      years[i] = i.toString();
    }
  }, [arr_to_year, years]);

  return (
    <div className=" gap-[25px] w-[100%] lg:w-[100%] flex flex-col justify-between lg:flex-row mb-[50px] ">
      <div className="w-[100%] h-[100%] flex flex-col md:flex-row gap-[25px] ">
        <FilterItem
          filter={filter}
          items={tags}
          name="Topics"
          internal_name="topic"
          update_filter={update_filter}
        />
        <FilterItem
          filter={filter}
          items={categories}
          name="Category"
          internal_name="category"
          update_filter={update_filter}
        />
        <FilterItem
          filter={filter}
          items={years}
          name="Archives"
          internal_name="year"
          update_filter={update_filter}
        />
        <FilterSearch filter={filter} update_filter={update_filter} />
      </div>
    </div>
  );
}
export function FilterSearch({
  filter,
  update_filter,
}: {
  filter: newsFilter;
  update_filter: (fn: (old: newsFilter) => newsFilter) => void;
}) {
  const search_input = useRef(null as unknown as HTMLInputElement);
  const do_update = useCallback(() => {
    if (search_input.current?.value === "") {
      update_filter((old) => ({
        ...old,
        search: undefined,
      }));
      return;
    }
    update_filter((old) => ({
      ...old,
      search: search_input.current?.value ?? "",
    }));
  }, [update_filter]);
  return (
    <div className="w-[100%] relative lg:w-[25%] flex flex-row bg-[#fff] ">
      <input
        ref={search_input}
        onKeyUp={(ev) => {
          if (ev.key === "Enter") {
            do_update();
          }
        }}
        id="searchBox"
        className="w-[100%] text-[22px] pl-[20px] pr-[50px] !border-[#949494] outline-none bg-[#fff]"
        type="search"
        defaultValue={filter.search}
        placeholder="Search..."
        aria-label="Search posts..."
      />
      <button
        type="button"
        className="absolute right-[20px] top-1/2 translate-y-[-50%]"
        aria-label="Search Posts"
        onClick={() => {
          do_update();
          // search_input.current?.focus();
        }}
      >
        <CiSearch size={30} color={"#000"} />
      </button>
    </div>
  );
}
export function FilterItem({
  filter,
  items,
  name,
  update_filter,
  internal_name,
}: {
  filter: newsFilter;
  items: Record<number, string>;
  name: string;
  internal_name: keyof newsFilter;
  update_filter: (fn: inner_update) => void;
}) {
  return (
    <select
      value={filter[internal_name] ?? "all"}
      onChange={(ev) => {
        update_filter((old) => ({
          ...old,
          [internal_name]:
            ev.target.value === "all" ? undefined : Number(ev.target.value),
        }));
      }}
      className="px-3 w-[100%] lg:w-[33%] text-[22px] border-b-[0.5px] border-[#949494] py-[10px] font-semibold"
    >
      <option value="all">{name}</option>
      {Object.entries(items).map(([i, item]) => (
        <option
          // biome-ignore lint/security/noDangerouslySetInnerHtml: it's fine
          dangerouslySetInnerHTML={{ __html: item }}
          key={item}
          value={i}
        />
      ))}
    </select>
  );
}
